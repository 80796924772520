import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'

import { toggleShowSettings, setShowFilters, setShowSettings, toggleShowFilters } from '../../redux/actions'
import { getRenderingState, getIsApp, getThemeName } from '../../redux/selectors/rendering'

import useWindowResize from '../../hooks/useWindowResize'

import { beta, desktop } from '../../utils/functions'
import { playStore, iosStore } from '../../utils/links'

import cog from '../../images/cog.png'
import leftArrow from '../../images/left-arrow.png'
import burgerMenu from '../../images/burger-menu.png'
import ios_app_store from '../../images/app_stores/apple.svg'
import play_store from '../../images/app_stores/google.svg'

const NavbarButtons = (props) => {
	const [device] = useWindowResize()
	const themeName = get(props, `themeName`)

	/**
	 * Respond to a click of the burger menu icon
	 */
	const burgerClick = () => {
		if (get(props, `rendering`, `showFilters`)) {
			get(props, `setShowFilters`)(false)
		}
		get(props, `toggleShowSettings`)()
	}

	/**
	 * @returns JSX
	 */
	const render = () => {
		if (!desktop(device)) {
			return (
				<>
					<img onClick={burgerClick} alt="settings" className={`burger fixed settings ${themeName}`} src={burgerMenu} />
					<img
						onClick={() => {
							if (!get(props, [`rendering`, `showFilters`])) {
								get(props, `setShowSettings`)(false)
							}
							get(props, `toggleShowFilters`)()
						}}
						alt="filters-toggle"
						className={`fixed filters-toggle  ${themeName}`}
						src={get(props, [`rendering`, `showFilters`]) ? leftArrow : cog}
					/>
				</>
			)
		}

		let appStoreLinks = null
		if (beta() && !get(props, `isApp`)) {
			appStoreLinks = (
				<div>
					<img onClick={playStore} alt="google_play" src={play_store} className={`play-store`} />
					<img onClick={iosStore} alt="ios_app_store" src={ios_app_store} className={`app-store`} />
				</div>
			)
		}
		return (
			<div className={`fixed settings navbar-buttons ${themeName}`}>
				{appStoreLinks}
				<img onClick={burgerClick} alt="settings" className={`burger ${themeName}`} src={burgerMenu} />
			</div>
		)
	}

	return render()
}

const mapStateToProps = (state) => {
	const rendering = getRenderingState(state)
	const isApp = getIsApp(state)
	const themeName = getThemeName(state)

	return {
		themeName,
		rendering,
		isApp,
	}
}

export default connect(mapStateToProps, { toggleShowFilters, toggleShowSettings, setShowFilters, setShowSettings })(NavbarButtons)
