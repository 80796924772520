import React, { useEffect, Suspense } from 'react'
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom'
import AppUrlListener from '../UrlListener.tsx'

import { QueryParamProvider } from 'use-query-params'

import RhythmbotPage from '../pages/RhythmBot'
import { get as idbGet, set as idbSet } from 'idb-keyval'
import useGlobalRefs from '../hooks/useGlobalRefs'

const Router = window.cordova ? HashRouter : BrowserRouter

const ReactTheme = React.lazy(() => import('../styles/loaders/react'))
const ThemeSelector = ({ children }) => {
	return (
		<>
			<Suspense fallback={<></>}>
				<ReactTheme />
				{children}
			</Suspense>
		</>
	)
}

const RBRoutes = () => {
	const refs = useGlobalRefs()

	/**
	 * Keep track of page views
	 */
	useEffect(() => {
		idbGet('pageViews').then((value) => {
			if (typeof value === 'number') {
				idbSet('pageViews', value + 1)
				return
			}
			idbSet('pageViews', 1)
			idbSet('pageViewMultiplier', 1.0)
		})
	}, [])

	return (
		<>
			<ThemeSelector>
				<Router>
					<AppUrlListener></AppUrlListener>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Switch>
							<Route exact path="/">
								<RhythmbotPage refs={refs} />
							</Route>
							<Route exact path="/language/:languageCode">
								<RhythmbotPage refs={refs} />
							</Route>
						</Switch>
					</QueryParamProvider>
				</Router>
			</ThemeSelector>
		</>
	)
}

export default RBRoutes
